import React, { FC } from 'react';
import { IconSVGProps } from '../Icon';

export const DiaryIcon: FC<IconSVGProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="book, guide, info, faq">
      <path
        id="vector"
        d="M19.25 12V15.75C19.25 16.3023 18.8023 16.75 18.25 16.75H7C5.75736 16.75 4.75 17.7574 4.75 19C4.75 20.2426 5.75736 21.25 7 21.25H10M8.75 7H15.25M8.75 11H12.25M6.75 2.75H18.25C18.8023 2.75 19.25 3.19772 19.25 3.75V20.25C19.25 20.8023 18.8023 21.25 18.25 21.25H6.75C5.64543 21.25 4.75 20.3546 4.75 19.25V4.75C4.75 3.64543 5.64543 2.75 6.75 2.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
